import { graphql } from "gatsby";
import React from "react";
import { Box } from "theme-ui";

export const NotFound = () => {

  return (
    <Box>
      <h1>404</h1>
    </Box>
  );
};

export default NotFound;
